<template>
  <div id="home">
    <Slider/>
    <div id="content">
      <h1>A holistic way to improving your health.</h1>
      <div id="intro">
        <p>Let us show you how Chinese medicine can help you:</p>
        <p>Traditional Chinese Medicine (TCM), is a traditional medical system. 
            It has a holistic approach to diagnosing, preventing and treating diseases by identifying patterns and then applying the individual or 
            combined therapies of acupuncture, Chinese herbal medicine, tuina - a therapeutic massage; and other techniques. Its unique 
            characteristics which distinguish it from ‘orthodox’ medicine are rooted in the “concept of holism - zheng ti guan nian” and “treatment 
            according to syndrome differentiation - bian zheng lun zhi”. The fundamental theories of TCM include those of Qi, Yin Yang, the five 
            elements, zang-fu, the four diagnostic methods and syndrome differentiation systems.</p>
      </div>
      <h1>Can Chinese Medicine Help You?</h1>
      <div id="help">
        <p> We have laid out some common names for the problems that we provide treatments.<br>
            However, this is a mere a list of some problems that we deal with.<br>
            If you have any other symptoms that concerns you and you would like to discuss, please contact us for a free and confidental consultation in our clinic.<br>
            We can also help with Long Covid Syndromes and Covid Vaccine side-effects
        </p>
        <div id="problemlist" v-for="item in list" :key="item">
          <h2>{{ item.alpha }}</h2>
          <p>{{ item.problem }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from '../components/Slider.vue'
export default {
  name: 'Nav-Home',
  components: {
    Slider
  },
  data() {
    return {
      list: [
        { alpha: "A", problem: "Acne, Acid reflux, Addiction, Allergic rhinitis, Anxiety, Arrhythmias, Arthritis, Asthma, Athlete’s feet"},
        { alpha: "B", problem: "Bell’s palsy, Back Pain"},
        { alpha: "C", problem: "Cancer care, Carpal tunnel syndrome, Chronic fatigue, COPD, Coronary heart disease, Covid Vaccine side-effects, Cystitis, Cramps, Cold & Flu, Constipation"},
        { alpha: "D", problem: "Dementia, Depression, Dysmenorrhea, Dental discomfort, Diabetes, Diarrhoea"},
        { alpha: "E", problem: "Eczema, Endometriosis"},
        { alpha: "F", problem: "Facial pain, Fibromyalgia, Frozen shoulder"},
        { alpha: "G", problem: "Gastrointestinal tract disorders, Gout"},
        { alpha: "H", problem: "Halitosis (bad breath), Headaches, Hemorrhoids, Hypertension, Hay fever, High cholesterol"},
        { alpha: "I", problem: "Infertility (for women & men), Insomnia, Irritable bowel syndrome (IBS)"},
        { alpha: "K", problem: "Kidney problems (including kidney stones)"},
        { alpha: "L", problem: "Liver problems"},
        { alpha: "M", problem: "Myofascial pain, Menopausal syndrome, Migraines, Multiple sclerosis (MS), ME"},
        { alpha: "N", problem: "Nausea & vomiting, Neck pain, Neuropathic pain"},
        { alpha: "O", problem: "Obesity, Obstetrical care, Odema, Otitis (infection of ear), Osteo-arthritis"},
        { alpha: "P", problem: "Prostate problems, Panic attacks, Psoriasis, Palliative care, Parkinson’s disease, Polycystic ovarian syndrome (PCOS), Post traumatic stress disorder (PTSD), Premenstrual syndrome, Postnatal care"},
        { alpha: "R", problem: "Raynaud’s, Rheumatoid arthritis, Rhinitis"},
        { alpha: "S", problem: "Sciatica, Sinusitis, Skin problems, Sports Injuries, Stress, Stroke, Sex dysfunction, Stop smoking, Sore throat"},
        { alpha: "T", problem: "Tennis elbow, Thyroid disease, Tinnitus"},
        { alpha: "U", problem: "Urinary problems"},
        { alpha: "V", problem: "Vaginitis, Varicose veins, Vertigo"},
        { alpha: "W", problem: "Weight Loss"}
      ]
    }
  }
}
</script>

<style>
#intro {
  text-align: left;
}
#help {
  text-align: left;
}

@media only screen and (min-width: 320px) {
  #content h1 {
    padding-top: 15px;
    font-size: 4vw;
    padding-bottom: 5px;
  }
  #intro {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  }
  #help {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  }
  #help h2 {
    padding-top:10px;
    padding-bottom: 0px;
    font-size: 4vw;
  }
}
@media only screen and (min-width: 1000px) {
  #content h1 {
    padding-top: 20px;
    font-size: 2vw;
    padding-bottom: 20px;
  }
  #intro {
    padding-left: 15%;
    padding-right: 15%;
    width: 70%;
  }
  #help {
    padding-left: 15%;
    padding-right: 15%;
    width: 70%;
  }
  #help h2 {
    padding-top:20px;
    padding-bottom: 0px;
    font-size: 2vw;
  }
}

</style>
