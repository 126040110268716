import { createRouter, createWebHistory } from "vue-router";
import Home from '../views/Home.vue'
import Treatments from '../views/Treatments.vue'
import Testimonials from '../views/Testimonials.vue'
import FAQ from '../views/FAQ.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/treatments',
    name: 'Treatments',
    component: Treatments
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: Testimonials
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
