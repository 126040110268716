<template>
  <div class="treatments">
    <h1>TREATMENTS</h1>
    <div id="treatments">
      <p>Here are a few of the treatments available:</p>
      <div id="available" v-for="treatment in treatmentslist" :key="treatment">
        <p>{{ treatment.name }}</p>
      </div>
    </div>
    <div id="costs">
      <h1>Rates</h1>
        <div class="consultation">
        <p>Visit us for a FREE CONSULTATION to talk about your concerns</p>
        <p>Free blood pressure test</p>
      </div>
      <h3>Treatment:</h3>
      <div class="treatment-costs" v-for="rate in rates" :key="rate">
        <p>{{ rate.name }}: {{ rate.price}}</p>
      </div>
    </div>
    <div class="offer">
      <h1>Special Offer</h1>
      <p>Acupuncture + Cupping + Meridian Massage (Tuina) = ONLY £69</p>
      <p>Course treatment offers available</p>
      <p>Visit us to get the latest offers!</p>
    </div>
    <div class="information">
      <h1>Treatment Information</h1>
      <div class="procedures" v-for="procedure in procedures" :key="procedure">
        <h3>{{ procedure.name }}</h3>
        <img :src="procedure.image" id="images" v-if="procedure.image">
        <p>{{ procedure.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav-Treatments',
  components: {
  },
  props: {
    msg: String
  },
  data() {
    return {
      treatmentslist: [ 
        { id: 1, name: "Acupuncture"},
        { id: 2, name: "Chinese herbs" },
        { id: 3, name: "Cupping" },
        { id: 4, name: "Ear Candling" },
        { id: 5, name: "Fertility Counseling" },
        { id: 6, name: "Hijama" },
        { id: 7, name: "Massage" },
        { id: 8, name: "Moxibustion" },
        { id: 9, name: "Nutritional Counseling" }
      ],
      rates:[
        { name: "Acupuncture", price: "£59"},
        { name: "Cupping", price: "£30"},
        { name: "Ear Candling", price: "£25"},
        { name: "Guasha", price: "£25"},
        { name: "Hijama", price: "£45"},
        { name: "Herbal Remedy", price: "from £59 / Week"},
        { name: "Meridian Massage (tuina)", price: "£69"},
        { name: "Moxibustion", price: "from £25 - £49"}
      ],
      procedures:[
        { name: "Acupuncture", image: require('@/assets/shop-treatment-acupuncture.png'), description: "Acupuncture works by stimulating the acu-points which lie on lines or channels, through the insertion of thin, fine needles at various points on the body. During the centuries of clinical practice, the functions and clinical effectiveness of each acu-point or combinations of such points in treating different diseases have been noted. In recent years these actions have been systematically studied and verified by modern scientific researches. Currently, there are different forms of acupuncturists in the West; our members only practice the traditional Chinese acupuncture which is based on the full TCM meridian and collateral and the syndrome differentiation theories."},
        { name: "Chinese Herbs", image: require('@/assets/shop-herbs.jpg'), description: "Traditionally, Chinese Herbal Medicine (CHM) involves the use of natural plants, minerals and even some small amount animals. However, TCM practitioners are not allowed to use minerals and animal stuff. All ATCM members are only using herbs in their clinical practice. Each herb has its own specific characteristics and particular medical use to treat various diseases, rectifying the over-activity or under-activity of Yin and Yang, and helping restore the body to its normal physiological functions. Chinese herbal therapy must be given by qualified TCM practitioners. Normally, the practitioner must conduct a diagnostic consultation, such as asking you questions that relate to your health problems, taking your pulse and observing your tongue, before making a prescription. A prescription can be defined as a preparation which, on the basis of syndrome differentiation and accordingly established therapeutic methods, organically combines various herbs in accordance with Chinese medicine principles."},
        { name: "Cupping", image: require('@/assets/shop-treatment-cupping.jpg'), description: "Cupping therapy is an ancient form of Chinese medicine in which a therapist puts special cups on your skin for a few minutes to create suction. People get it for many purposes, including to help with pain, inflammation, blood flow, relaxation and well-being, and as a type of deep-tissue massage."},
        { name: "Gua Sha", image: require('@/assets/shop-treatment.jpg'), description: "Gua sha, meaning “scraping sha-bruises”, is a traditional Chinese medical treatment in which the skin is scraped to produce light bruising. Practitioners believe gua sha releases unhealthy elements from injured areas and stimulates blood flow and healing."},
        { name: "Chinese Medical Massage (Tuina)", image: require('@/assets/shop-treatment-massage.jpg'), description: "Tuina (pronounced ‘twee-nah’) is a form of Chinese therapeutic massage. ‘Tui’ meaning to push and ‘Na’ to grasp. Tuina is a very important component of Chinese medicine alongside acupuncture and herbal prescription. However, Tuina can be a complete healing system in itself as not only does it work on muscle groups, joints, sites of pain and poor circulation, but it works the same meridians and points used in acupuncture and employs the same basic theory as Traditional Chinese Medicine (TCM): 'Injury or disease causes blockages in the channels of the body. Blockages cause pain.'"},
        { name: "Moxibustion", image: require('@/assets/shop-treatment-moxibustion.jpg'), description: "In traditional Chinese medicine, moxibustion is used on people who have a cold or stagnant condition. The burning of moxa is believed to expel cold and warm the meridians, which leads to smoother flow of blood and qi. In Western medicine, moxibustion has successfully been used to turn breech babies into a normal head-down position prior to childbirth. A landmark study published in the Journal of the American Medical Association in 1998 found that up to 75% of women suffering from breech presentations before childbirth had fetuses that rotated to the normal position after receiving moxibustion at an acupuncture point on the Bladder meridian. Other studies have shown that moxibustion increases the movement of the fetus in pregnant women, and may reduce the symptoms of menstrual cramps when used in conjunction with traditional acupuncture."},
        { name: "Hijama", image: "", description: "Hijama is the Arabic traditional medicine for wet cupping, where blood is drawn by vacuum from a small skin incision for therapeutic purposes. It is reported that the Islamic prophet Muhammad said, “Indeed the best of remedies you have is hijama, and if there was something excellent to be used as a remedy then it is hijama.Hijama can be performed almost anywhere on the body, often at the site of an ache or pain in order to ease or alleviate it."}
      ]
    }
  }
}
</script>

<style scoped>
.information h1 {
  padding-bottom: 0px;
}
@media only screen and (min-width: 320px) {
  #images {
    height: 150px;
    width: 75%;
    object-fit: cover;
  }
  h1 {
    padding-top: 15px;
    font-size: 5vw;
    padding-bottom: 10px;
  }
  h3 {
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 4vw;
    color: #74912B;
  }
  .treatment {
    font-size: 3vw;
  }
  .treatments {
    padding-top: 15px;
  }
  #treatments {
    padding-top: 5px;
  }
  .treatment-costs p {
    font-size: 4vw;
  }
  .procedures {
    text-align: left;
    padding-left: 5%;
    padding-right: 5%;
  }
  .information {
    padding-top: 10px;
    padding-bottom: 0px;
  }
}
@media only screen and (min-width: 1000px) {
  #images {
    height: 300px;
    width: 450px;
    object-fit: cover;
  }
  h1 {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 2vw;
  }
  h3 {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 2vw;
  }
  .treatments {
    font-size: 2vw; 
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 15px;
  }
  .offer {
    float: left;
    padding-top: 20px;
    padding-left: 14%;
    padding-right: 14%;
  }
  #treatments{
    text-align: left;
    width: 50%;
    float: left;
  }
  .treatment-costs p {
    text-align: left;
    font-size: 2vw;
  }
  #costs {
    text-align: left;
    width: 50%;
    float: left;
  }
  #available {
    text-align: left;
  }
  .treatment-costs {
    text-align: left;
  }
  .consultation {
  }
  .information {
    float: left;
    padding-top: 20px;
    padding-bottom: 0px;
    font-size: 2vw;
  }
}
</style>
