<template>
  <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0">
  <div id="app">
    <div id="logo">
      <img id="shop-logo-chinese" alt="Logo" src="./assets/logo-chinese.png">
      <img id="shop-logo-english" alt="Logo" src="./assets/logo-english.png">
    </div>
    <h1>Personalised Health Care For You</h1>
    <h2>Traditional Chinese Medicine, Acupuncture, Massage, Herbal Remedy</h2>
    <div class="navbar">
      <router-link to="/">Home</router-link>
      <router-link :to="{ name: 'Treatments' }">Treatments</router-link>
      <router-link :to="{ name: 'Testimonials' }">Testimonials</router-link>
      <router-link :to="{ name: 'FAQ' }">FAQ</router-link>
      <router-link :to="{ name: 'About' }">About</router-link>
      <router-link :to="{ name: 'Contact' }">Contact</router-link>
    </div>
  </div>
  <router-view/>
</template>

<script>
export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* background-color: #CCDCAA; */
  /* background-color: #2F5E2E; */
}
#logo {
  background-color: #2F5E2E;
}

.navbar {
  width: 100%;
  background-color: #555;
  overflow: auto;
  padding-left: 0%;
  padding-right: 0%;
}

.navbar a:hover {
  background-color: #000;
}

.navbar a.router-link-active {
  background-color: #2F5E2E;
}

@media only screen and (min-width: 320px) {
  #shop-logo-chinese {
    padding-top: 10px;
    max-width: 35%;
    align-content: center;
    vertical-align: middle;
  }
  #shop-logo-english {
    padding-bottom: 10px;
    max-width: 70%;
    align-content: center;
    vertical-align: middle;
  }
  #app h1 {
    font-size: 4vw;
    font-style: bold;
    color: #74912B;
    padding-top: 10px;
  }
  #app h2 {
    font-size: 3vw;
    font-style: bold;
    color: #74912B;
    padding-bottom: 10px;
  }
  .navbar a {
    float: left;
    color: white;
    text-decoration: none;
    font-size: 2.4vw;
    width: 16.66%;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
@media only screen and (min-width: 1000px) {
  /* For desktop: */
  #shop-logo-chinese {
    padding-top: 0px;
    min-width: 10%;
    max-width: 25%;
    align-content: center;
    vertical-align: middle;
  }
  #shop-logo-english {
    padding-bottom: 0px;
    max-width: 45%;
    width: 50%;
    align-content: center;
    vertical-align: middle;
  }
  #app h1 {
    font-size: 3vw;
    font-style: bold;
    color: #74912B;
  }
  #app h2 {
    font-size: 2vw;
    font-style: bold;
    color: #74912B;
  }
  .navbar {
    width: 72%;
    padding-left: 14%;
    padding-right: 14%;
  }
  .navbar a {
    float: left;
    color: white;
    text-decoration: none;
    font-size: 2vw;
    width: 16.66%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

</style>
