<template>
  <div class="contact">
    <div id="contactdetails">
      <div class="details">
        <h1>CONTACT US</h1>
        <p>Mobile: <a href="tel:07515279108">07515 279108</a></p>
        <p>Tel: <a href="tel:01562227280">0156 222 7280</a></p>
        <p>Email: <a href="mailto:contact@drchinawellness.co.uk">contact@drchinawellness.co.uk</a></p>
      </div>
      <div class="container">
        <h2>SEND A MESSAGE</h2>
        <form @submit.prevent="sendEmail">
          <label>Name</label>
          <input 
            type="text" 
            v-model="name"
            name="name"
            placeholder="Your Name"
          >
          <label>Email</label>
          <input 
            type="email" 
            v-model="email"
            name="email"
            placeholder="Your Email"
            >
          <label>Message</label>
          <textarea 
            name="message"
            v-model="message"
            cols="30" rows="5"
            placeholder="Your Message">
          </textarea>
          <input type="submit" value="Send">
        </form>
      </div>
    </div>
    <div id="visitus">
      <h1>VISIT US</h1>
      <div class="address">
        <b style="color:#2F5E2E;">Dr. China Wellness</b><br>
        108 Coventry Street<br>
        Kidderminster<br>
        DY10 2BH<br>
      </div>
      <div class="google-maps">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4869.985913449458!2d-2.247022!3d52.388678!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48708c5c0fe1be89%3A0xd107cc80919b912a!2sDr.%20China%20Wellness%20(Traditional%20Chinese%20Medicine%2C%20Acupuncture%2C%20herbs)!5e0!3m2!1sen!2suk!4v1586521234615!5m2!1sen!2suk"
          height="450"
          width="600"
          frameborder="0"
          style="border:0;"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
    <a href="https://www.facebook.com/drchinawellness" title="facebook" target="_blank" class="hyperlink">
          <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
          <font-awesome-icon class="contactMediaTitle" :icon="{ prefix: 'fab', iconName: 'facebook-square' }"/>
        </a>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import emailjs from 'emailjs-com';

library.add(faFacebookSquare, faEnvelope)

export default {
  name: 'Contact-Us-Page',
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm('service_b4qbnne', 'template_kv81sdc', e.target, 'ychCXjb1RByo8VVVK', {
          name: this.name,
          email: this.email,
          message: this.meessage
        })
      } catch (error) {
          console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
    },
  }
}
</script>

<style scoped>
.contactMediaTitle {
  color: black;
  line-height: 2;
  font-size: 2em;
}
.address {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-style: normal;
  font-size: 1.2em;
  color: black;
  padding: 5px 0px 5px 0px;
}
.container {
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
}
label {
  float: left;
}
input[type=text], [type=email], textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #74912B;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #2F5E2E;
}

@media only screen and (min-width: 320px) {
  .google-maps {
    position: relative;
    padding-bottom: 75%;
    height: 0;
    overflow: hidden;
  }
  .google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
  #contactdetails {
    padding-top: 10px;
  }
  #visitus {
    padding-top: 10px;
  }
  #contactdetails h1 {
    font-size: 4vw;
  }
  #visitus h1 {
    font-size: 4vw;
  }
  .details {
    font-weight: bold;
    padding-top: 5px;
    font-size: 4vw;
  }
}
@media only screen and (min-width: 1000px) {
  .google-maps {
    position: relative;
    padding-bottom: 75%;
    height: 0;
    overflow: hidden;
  }
  .google-maps iframe {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100% !important;
    height: 100% !important;
  }
  .contact {
    padding-left: 15%;
    padding-right: 15%;
    width: 70%;
    overflow: hidden;
  }
  #contactdetails {
    width: 50%;
    float: left;
    padding-top: 20px;
  }
  #visitus {
    width: 50%;
    float: left;
    padding-top: 20px;
  }
  #contactdetails h1 {
    font-size: 2vw;
    padding-bottom: 20px;
  }
  #visitus h1 {
    text-align: left;
    font-size: 2vw;
    padding-bottom: 20px;
  }
  .details {
    text-align: left;
    font-weight: bold;
    font-size: 1.5vw;
  }
  .details a:hover { 
    text-decoration: none;
    color: #707070;
  }
  .details a:active {
    text-decoration: none;
    color: #707070;
  }
  .details a:link {
    text-decoration: none;
    color: #707070;
  }
  .address {
    text-align: left;
  }
}
</style>
