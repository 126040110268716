<template>
  <div id="about">
    <div id="title">
      <h1>ABOUT</h1>
    </div>
    <div id="practitioner">
      <p><b>Dr. Yanhua Sun</b></p>
      <p>MBBS (Chinese Medicine)</p>
      <p>ChangChun TCM University, China</p>
    </div>
    <img src="@/assets/profile.png" id="profileimg" alt="Profile Image">
    <div id="experience">
      <p>Professional Practitioner Of Traditional Chinese Medicine (TCM) with <b>over 30 years</b> of practicing <b>experience</b> in TCM.</p>
      <p><b>1983-1988</b> Studied Traditional Chinese Medicine (Bachelor of Traditional Chinese Medicine) for five years at the University of Changchun, China. Achieved the top graduate of the year.</p>
      <p><b>1988-2003</b> Practiced TCM in Changchun TCM Hospital, China, for 15 years.</p>
      <p>Further studied TCM with clinic TCM Specialist Prof. Zhengshen Shi, TCM gynaecology specialist Dr.Zhongmeng Yang and Acupuncture/Tuina specialist Dr. Fei Liu.</p>
      <p>Practiced Traditional Chinese Medicine in the UK since 2003 in Birmingham, Cardiff and Coventry</p>
      <p>Founded <b>Dr. China Wellness</b> in Kidderminster in late 2015.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav-About',
  components: {
    
  }
}
</script>

<style>

#profileimg {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media only screen and (min-width: 320px) {
  #title {
    padding-top: 15px;
    font-size: 4vw;
    padding-bottom: 5px;
  }
  #experience p {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10px;
    text-align: left;
  }
}
@media only screen and (min-width: 1000px) {
  #title {
    padding-top: 20px;
    font-size: 2vw;
    padding-bottom: 20px;
  }
  #experience p {
    width: 70%;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 20px;
    text-align: left;
  }
}

</style>
