<template>
  <div id="faq">
    <div id="title">
      <h1>FREQUENTLY ASKED QUESTIONS</h1>
    </div>
    <div id="frequentlyasked" v-for="question in questions" :key="question">
      <p><b>Q: {{ question.q }}</b></p>
      <p><b>A: </b>{{ question.a }}</p>
      <br/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav-FAQ',
  components: {
    
  },
  data() {
    return {
      questions: [
        { q: "Does acupuncture hurt?", a: "Generally, acupuncture does not hurt. Some people may feel the needles as they go in, and some people feel nothing. Acupuncture needles are very fine solid needles, not hollow like hypodermic needles, and they are much, much thinner - about the diameter of a thick human hair."},
        { q: "Is acupuncture safe?", a: "Acupuncture is a very safe practice in the hand of an experienced practitioner. Our practitioner is highly skilled with 30 years of clinical experience in this field. You can be confident in us."},
        { q: "What does the herbal medicine taste like?", a: "It has a bitter taste! But it is good for your health. An old saying in Chinese, “liang yao ku kou”, meaning 'bitter pills, better health'."},
        { q: "Why do you call TCM as a personalised medicine?", a: "We carefully select the treatments according to each individual’s signs and symptoms. Although people can have the same “disease”, people may have a different nature in their body system. This is reflected in the treatment variations. For example, in each herbal remedy, we carefully select around 10- 15 different natural herbs out of ~300 to suit for each individual client. We do not believe one pill fits all…"},
        { q: "Do you have evidence to support TCM?", a: "TCM is around about 2000 years in China. There are many books documenting the effectiveness of the treatments. Recently, in 2015, the medical Nobel Prize winner went to Youyou Tu, a TCM scientist proved the use of 'QingHaoSu' to cure malaria. It is an ancient remedy in Chinese herbal medicine to treat malaria for hundreds of years. And many more…"},
        { q: "How popular is Traditional Chinese Medicine (TCM)?", a: "TCM is an important part of Chinese National Health Care System with over 1000 government funded TCM hospitals serving 13 million people nationwide. In fact, each and every Chinese benefits from TCM treatment in one way or another."},
        { q: "How is TCM doing in the UK?", a: "Since the introduction of TCM to the western world in the last 20 years, TCM has gradually being recognised and accepted in the western medicine. Many western medical doctors openly admit the effectiveness of TCM, especially in the management of pain. Such as acupuncture for muscular pain, TMJ disorders, fibromyalgia, etc. Some common TCM practice such as acupuncture and Tuina (medical massage) is taught in the western medical curriculum nowadays."}
      ]
    }
  }
}
</script>

<style>

@media only screen and (min-width: 320px) {
  #title {
    padding-top: 15px;
    font-size: 4vw;
    padding-bottom: 5px;
  }
  #frequentlyasked p {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    text-align: left;
  }
}
@media only screen and (min-width: 1000px) {
  #title {
    padding-top: 20px;
    font-size: 2vw;
    padding-bottom: 20px;
  }
  #frequentlyasked p {
    width: 60%;
    padding-left: 20%;
    padding-right: 20%;
    text-align: left;
  }
}

</style>
