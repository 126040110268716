<template>
  <div class="images">
    <Carousel class="carousel" :itemsToShow="1" :autoplay="4000" :wrap-around="true" >
      <Slide v-for="image in images" :key="image.id">
        <img :src="image.url" />
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default {
  name: 'Nav-Slider',
  components: {
    Carousel,
    Slide
  },
  props: {
    msg: String
  },
  data() {
    return {
      images: [
        {id: 1, url: require('@/assets/shop-store-front-night.jpg')},
        {id: 2, url: require('@/assets/shop-reception.jpg')},
        {id: 3, url: require('@/assets/shop-herbs.jpg')},
        {id: 4, url: require('@/assets/shop-herbal-remedy.jpg')},
        {id: 5, url: require('@/assets/shop-treatment-massage.jpg')},
        {id: 6, url: require('@/assets/shop-treatment-acupuncture.png')},
        {id: 7, url: require('@/assets/shop-treatment-cupping.jpg')},
        {id: 8, url: require('@/assets/shop-treatment.jpg')}
      ]
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 320px) {
  .carousel img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
@media only screen and (min-width: 1000px) {
  /* For desktop: */
  .carousel img {
    width: 50%;
    object-fit: cover;
  }
}
</style>
